body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, MisterPixel, MisterPixelTools;
}
/* 
@font-face {
  font-family: MisterPixel;
  src: url("./fonts/MisterPixelRegular.otf") format("opentype");
}

@font-face {
  font-family: MisterPixelTools;
  src: url("./fonts/MisterPixelTools.otf") format("opentype");
}

@font-face {
  font-family: Steps-Mono;
  src: local("Steps-Mono"), url("./fonts/Steps-Mono.otf") format("opentype");
}

@font-face {
  font-family: Steps-Mono-Thin;
  src: local("Steps-Mono-Thin"),
    url("./fonts/Steps-Mono-Thin.otf") format("opentype");
} */
