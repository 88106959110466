@import "https://fonts.googleapis.com/css?family=Roboto+Mono:100";

$background-1: #212121;
$background-2: #3b3b3b;

$text-1: #fafafa;
$text-2: #39ff14;
$text-3: #757575;

$yellow: #ffcc00;

@font-face {
  font-family: Steps-Mono;
  src: local("Steps-Mono"), url("./fonts/Steps-Mono.otf") format("opentype");
}

@font-face {
  font-family: Steps-Mono-Thin;
  src: local("Steps-Mono-Thin"),
    url("./fonts/Steps-Mono-Thin.otf") format("opentype");
}

/* Using SCSS variables to store breakpoints */
$breakpoint-tablet: 768px;

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

html,
body {
  font-family: "Share Tech Mono", monospace;
  background: $background-1;
  color: $text-1;
  min-height: 100vh;
  text-align: center;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.stick {
  margin-top: auto;
}

.linkClick {
  color: $text-1;
  &:hover {
    color: $text-2;
    border-radius: 6px;
  }
  @media (max-width: $breakpoint-tablet) {
    font-size: 12px;
  }
}

.page {
  min-height: 100vh;
}

.header-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5vh;
  margin-right: 5vh;
  padding-top: 3vh;
  margin-bottom: 3vh;

  font-family: "Share Tech Mono", monospace;
  color: $text-1;
  font-size: 24px;

  @media (max-width: $breakpoint-tablet) {
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    align-items: center;
  }
}

.playlists {
  display: flex;
  flex-direction: row;

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }
}

.playlist-item {
  margin-left: 6px;
  margin-right: 6px;
}

.footer-wrap {
  display: flexbox;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vh;
  padding-bottom: 2vh;

  font-family: "Share Tech Mono", monospace;
  color: $text-1;
  font-size: 14px;
}

.title {
  display: flex;
  flex-direction: row;
  color: $text-1;
  font-size: 40px;
  align-items: center;
  justify-content: center;
  @media (max-width: $breakpoint-tablet) {
    font-size: 32px;
  }
}

.text-color-white {
  color: $text-1;
}

.text-color-white:hover {
  cursor: pointer;
  color: $text-2;
}

.text-color-white-end {
  color: $text-1;
}

.my-name {
  cursor: pointer;
  font-family: "Libre Barcode 39 Text", cursive;
  font-size: 48px;
  @media (max-width: $breakpoint-tablet) {
    font-size: 36px;
  }
}

.my-name:hover {
  cursor: pointer;
  color: $text-2;
}

.header-right-menu {
  display: flex;
  flex-direction: row;
  @media (max-width: $breakpoint-tablet) {
    margin-top: 12px;
  }
}

.header-item-left {
  cursor: pointer;
}

.header-item-left:hover {
  cursor: pointer;
  color: $text-2;
}

.header-item {
  padding-left: 2.5vh;
  cursor: pointer;
}

.header-item:hover {
  padding-left: 2.5vh;
  cursor: pointer;
  color: $text-2;
}

.text {
  font-weight: 100;
  font-size: 28px;
  color: $text-1;
}

@keyframes pixelate {
  0% {
    transform: scale(2);
    filter: blur(20px);
  }
  50% {
    transform: scale(1.1);
    filter: blur(10px);
  }
  100% {
    transform: scale(1);
    filter: blur(0);
  }
}

.photo {
  max-width: 25%;
  padding-top: 40px;
  justify-content: center;
  align-self: center;
  animation: pixelate 1s ease-in-out;
  image-rendering: pixelated;
}

.my-info {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-item {
  margin-bottom: 24px;
  justify-content: left;
}

.divider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 80px;
  margin-right: 80px;

  @media (max-width: $breakpoint-tablet) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.center-abs {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.enter-button {
  position: absolute;
  border-radius: 12px;
  top: 50%;
  left: 50%;
  margin-left: -50px; /* margin is -0.5 * dimension */
  margin-top: -25px;
  background: none;
  color: $yellow;
  outline: none;
  border: none;
  height: 50px;
  width: 190px;
  font-size: 14px;
  z-index: 2;
  transition: 0.01s 0.23s ease-out all;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 55%;
    background: $yellow;
    opacity: 20%;
    z-index: -1;
    transition: 0.3s ease-in all;
  }
  &:after {
    content: "";
    position: absolute;
    left: -5%;
    top: 5%;
    height: 90%;
    width: 5%;
    background: $yellow;
    z-index: -1;
    transition: 0.4s 0.02s ease-in all;
  }
  &:hover {
    cursor: pointer;
    color: transparent;
    &:before {
      left: 100%;
      width: 25%;
    }
    &:after {
      left: 100%;
      width: 70%;
    }
    .icon-right.after:after {
      left: -80px;
      color: $yellow;
      transition: 0.2s 0.2s ease all;
    }
    .icon-right.after:before {
      left: -104px;
      top: 14px;
      opacity: 0.2;
      color: $yellow;
    }
  }
}

.icon-right {
  position: absolute;
  top: 0;
  right: 0;
  &:after {
    content: "\2192";
    font-size: 24px;
    display: inline-block;
    position: relative;
    top: 26px;
    transform: translate3D(0, -50%, 0);
  }
  &.after:after {
    left: -250px;
    color: $background-1;
    transition: 0.15s 0.25s ease left, 0.5s 0.05s ease color;
  }
  &.after:before {
    content: "";
    position: absolute;
    left: -230px;
    top: 14px;
    opacity: 0;
    transition: 0.2s ease-in all;
  }
}

a {
  text-decoration: none;
}

@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css");

ul {
  justify-content: center;
  display: flex;
  padding: 0px;
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
    align-items: center;
  }
}
ul li {
  list-style: none;
  @media (max-width: $breakpoint-tablet) {
    padding-bottom: 12px;
  }
}
ul li a {
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 40px;
  text-align: center;
  text-decoration: none;
  color: #404040;
  margin: 0 30px;
  transition: 0.5s;
}
ul li a span {
  position: absolute;
  transition: transform 0.5s;
}
ul li a span:nth-child(1),
ul li a span:nth-child(3) {
  width: 100%;
  height: 3px;
  background: #404040;
}
ul li a span:nth-child(1) {
  top: 0;
  left: 0;
  transform-origin: right;
}
ul li a:hover span:nth-child(1) {
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s;
}

ul li a span:nth-child(3) {
  bottom: 0;
  left: 0;
  transform-origin: left;
}
ul li a:hover span:nth-child(3) {
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s;
}

ul li a span:nth-child(2),
ul li a span:nth-child(4) {
  width: 3px;
  height: 100%;
  background: #404040;
}
ul li a span:nth-child(2) {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: bottom;
}
ul li a:hover span:nth-child(2) {
  transform: scale(1);
  transform-origin: top;
  transition: transform 0.5s;
}
ul li a span:nth-child(4) {
  top: 0;
  right: 0;
  transform: scale(0);
  transform-origin: top;
}
ul li a:hover span:nth-child(4) {
  transform: scale(1);
  transform-origin: bottom;
  transition: transform 0.5s;
}

.linkedin:hover {
  color: #0077b5;
}
.linkedin:hover span {
  background: #0077b5;
}
.twitter:hover {
  color: #1da1f2;
}
.twitter:hover span {
  background: #1da1f2;
}
.email:hover {
  color: #d44638;
}
.email:hover span {
  background: #d44638;
}

.instagram:hover {
  color: #c32aa3;
}
.instagram:hover span {
  background: #c32aa3;
}
.github:hover {
  color: #ffffff;
}
.github:hover span {
  background: #ffffff;
}
.instagram:hover {
  color: #8a3ab9;
}
.instagram:hover span {
  background: #8a3ab9;
}

ul li a .twitter {
  color: #1da1f2;
}
ul li a:hover:nth-child(3) {
  color: #c32aa3;
}
ul li a:hover:nth-child(4) {
  color: #dd4b39;
}

.centered-text {
  display: flex;
  flex-direction: row;
  color: $text-1;
  font-size: 24px;
  align-items: center;
  justify-content: center;
}

.social-buttons {
  padding-top: 64px;
  @media (max-width: $breakpoint-tablet) {
    padding-top: 36px;
  }
}

.work-section {
  display: grid;
  flex-direction: row;
}

.grid-container-1 {
  display: grid;
  grid-template-columns: 0.75fr 1fr;
  gap: 24px 60px;
  align-items: center;
  padding-bottom: 24px;
  padding-top: 24px;

  @media (max-width: $breakpoint-tablet) {
    grid-template-columns: 0.5fr 1fr;
    gap: 12px 30px;
    margin-left: 12px;
    margin-right: 12px;
  }
}

.grid-container-2 {
  display: grid;
  grid-template-columns: 1fr 0.75fr;
  gap: 24px 60px;
  align-items: center;
  padding-bottom: 24px;
  padding-top: 24px;

  @media (max-width: $breakpoint-tablet) {
    grid-template-columns: 1fr 0.5fr;
    gap: 12px 30px;
    margin-left: 12px;
    margin-right: 12px;
  }
}

.photo-work-1 {
  max-width: 280px;
  justify-self: right;

  @media (max-width: $breakpoint-tablet) {
    max-width: 64px;
  }
}

.photo-work-2 {
  max-width: 280px;
  justify-self: left;

  @media (max-width: $breakpoint-tablet) {
    max-width: 64px;
  }
}

.description-1 {
  justify-self: left;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.description-2 {
  justify-self: right;
  text-align: right;
  display: flex;
  flex-direction: column;
}

.work-title {
  font-size: 32px;
  margin-bottom: 4px;

  @media (max-width: $breakpoint-tablet) {
    font-size: 16px;
  }
}
.work-position {
  font-size: 24px;
  margin-bottom: 2px;

  @media (max-width: $breakpoint-tablet) {
    font-size: 12px;
  }
}

.soon {
  margin-top: 24px;
}
